import clsx from 'clsx';
import { ComponentPropsWithoutRef, forwardRef } from 'react';
import { skeleton } from './skeleton.css';
import { borderRadius } from '../tokens';

export const Skeleton = forwardRef<
  HTMLDivElement,
  ComponentPropsWithoutRef<'div'> & {
    width?: number | string;
    height?: number | string;
    radius?: number;
  }
>(function Skeleton(
  { className, height = 12, width = 80, radius = borderRadius.r8, ...rest },
  ref,
) {
  return (
    <div
      ref={ref}
      style={{ height, width, borderRadius: radius }}
      className={clsx(className, skeleton)}
      {...rest}
    />
  );
});
